import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Mailchimp from "../mailchimp/react-mailchimp-form/index";
import "./style.css";
import TextField from "@mui/material/TextField";
// import axios from "axios";



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

export default function LandingPageModal() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };;

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <button
        type="button"
        className="main_landingPage__cta"
        onClick={handleOpen}
      >
        Join the waitlist now
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={`main_landingPage__modal-container ${classes.paper}`}>
            <img
              // src={
              //   "https://drive.google.com/uc?id=1wMTb57VluRIYO5wrLqGXZXtmeXyEaJ2e"
              // }
              alt="logo"
              id="transition-modal-logo"
            />
            <h2 id="transition-modal-title">Start Learning!</h2>
            <p id="transition-modal-caption">(No Credit Card Required)</p>
            <p id="transition-modal-description">
              When you sign up now you can get a{" "}
              <span id="transition-modal-description-highlight">
                100% Free Access
              </span>{" "}
              to the beta version of our app and will be the first to know when
              the app goes live on the app store.
            </p>
            <Mailchimp
              action={`https://instantreset.us14.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_KEY}&amp;id=${process.env.REACT_APP_MAILCHIMP_ID}`}
              //Adding multiple fields:
              fields={[
                {
                  name: "EMAIL",
                  placeholder: "Email",
                  type: "email",
                  required: true,
                },
                {
                  name: "NAME",
                  placeholder: "Name",
                  type: "text",
                  required: true,
                },
              ]}
              // Change predetermined language
              messages={{
                sending: "Sending...",
                success:
                  "Thank you for subscribing. You will be the first to know when we launch!",
                error: "An unexpected internal error has occurred.",
                empty: "You must enter an e-mail.",
                duplicate: "Too many subscribe attempts for this email address",
                button: "Lets get focused",
              }}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
