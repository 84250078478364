import styled from "styled-components";
import { screenBreakpointValues } from "../../../utils/contants";

export const FooterContainer = styled.footer`
  position: relative;
  /* padding-top: 48px; */
`;

export const BothFooterContainer = styled.div`
  background-color: #fff;
  position: relative;
  width: 100%;
  min-width: 375px;
`;

export const TopFooterContainer = styled.section`
  width: 100%;
  position: relative;
  display: flex;
`;

export const TopDesktopFooterContainer = styled.section`
  width: 100%;
  position: relative;
  display: flex;
  margin: auto;
  max-width: 100%;
  padding: 0 1.25rem;
  align-items: flex-start;
  cursor: pointer;
  justify-content: space-between;
  padding-bottom: 40px;

  @media screen and (min-width: ${screenBreakpointValues.lg}) {
    max-width: 90rem;
  }

  // smallest the components will shrink to
  @media screen and (max-width: ${screenBreakpointValues.sm}) {
    min-width: 375px;
    display: none;
  }
`;

export const TopMobileFooterContainer = styled.section`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background: ghostwhite;
  padding: 10px;

  .mobile-top-footer-big-button-container {
    max-width: 50px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #mobile_footer_big_button {
    text-align: center;
  }

  .mobile-top-footer-menu-container {
    width: 100%;
    margin: 10px 10px 0 10px;
    max-width: 462px;
    align-self: center;
    min-width: 300px;
  }

  #mobile-top-footer_menu_list {
    width: 100%;
  }

  .footer_menu_item a {
    font-size: 0.75rem;
    background: transparent;
    color: #413d45;
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    letter-spacing: -0.01em;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    opacity: 0.7;
    margin: 6px;
  }

  .footer_menu_item:hover a {
    text-decoration: underline;
  }

  .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin: 0;
  }

  @media screen and (min-width: ${screenBreakpointValues.sm}) {
    display: none;
  }
`;

export const FooterMenuSection = styled.section`
  flex-basis: 60%;
  display: flex;
`;

export const FooterRightSection = styled.section`
  flex-basis: 40%;
  display: flex;
  justify-content: flex-end;

  #footer_big_button {
    margin-left: 0;
  }
  #footer_big_button > span {
    width: auto;
  }

  .footerRightSection_items {
    width: auto;
    margin-right: 48px;
  }

  .footerRightSection_last_item {
    display: flex;
    flex-direction: column;
    width: auto;

    img {
      margin-bottom: 12px;
      max-width: 175px;
    }
  }
`;

export const FooterMenuSectionItem = styled.div`
  width: 100%;

  .footer_menu_header {
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: -0.025em;
    line-height: 2rem;
    margin: 0 0 1rem 0;
    min-height: 4rem;
    display: flex;
    align-items: flex-end;
  }

  .footer_menu_item a {
    font-size: 0.75rem;
    background: transparent;
    color: #413d45;
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    letter-spacing: -0.01em;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    opacity: 0.7;
    margin: 6px;
  }

  .footer_menu_item:hover a{
    text-decoration: underline;
  }
`;

export const BottomFooterContainer = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  flex-direction: column;
  width: 100%;
  background-color: #f5f5f5;
  padding: 1.5rem 2rem;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: -0.01em;
  line-height: 1rem;
  color: #4f4f4f;
  min-width: 100%;
  opacity: 0.7;
  text-align: center;
`;

export const SocialIconContainer = styled.ul`
  display: flex;
  margin-bottom: 16px;
  li {
    margin: 0 0.5rem;
    background-color: white;
    padding: 5px;
    border-radius: 23rem;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  li:hover {
    background: #e5e5e5;
  }

  .footer-social-icons {
    /* color: #ef2e3d; */
    color: #000;
  }

  .footer-social-anchor {
    text-decoration: none;
  }
`;