import styled from "styled-components";
import { screenBreakpointValues } from "../../utils/contants";


export const LayoutContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  
`;

export const PageContentContainer = styled.div<{ background: string }>`
  background: ${(p: any) => p.background || ""};
  align-self: stretch;
  min-height: 100%;
  position: relative;
  .global-container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }

  @media screen and (max-width: ${screenBreakpointValues.sm}) {
    min-width: 375px;
  }
`;