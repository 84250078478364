import React, { useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";

import {
  NavbarParentContainer,
  NavbarContainer,
  HeaderContainer,
  MainNavContainer,
  RightNavContainer,
  MainNavListItem,
  RightNavItems,
  BigButton,
  MobileNavContainer,
  MobileNavListContainer,
  MobileNavList,
  MobileNavListItem,
  ButtonDropDownContainer,
  MenuItemEdited,
  MenuEdited,
} from "./style";

// import logo_full from "../../../static/logo_full_v3.svg";
import logo_full from "../../../static/logo-1.svg";
import hamburgerMenuIcon from "../../../static/hamburger_nav.svg";
import cross_X from "../../../static/cross_X.svg";
import right_arrow from "../../../static/right_arrow.svg";


import Fade from "@mui/material/Fade";

import DropDown from "../../dropdown";

const NAVMENUITEMS: any = [
  {
    name: "Home",
    url: "/#",
  },
  {
    name: "About",
    url: "/about-us",
  },
  {
    name: "Podcast",
    url: "/podcast",
  },
  {
    name: "Contact",
    url: "/contact-us",
  },
];

const NAVMENURIGHTITEMS: any = [
  {
    name: "Help",
    url: "/help",
  },
  {
    name: "Login",
    url: "/login",
  },
];

const NAVMENURIGHTITEMSAUTHENTICATED = [
  {
    name: "Help",
    url: "/help",
  },
  {
    name: "Dashboard",
    url: "/dashboard",
  },
];

function Navbar() {
  let history = useHistory();
  const [isOpenMobile, setIsOpenMobile] = React.useState(false);
  const handleMobileMenuItemClick = (url:string) => {
    history.push(url);
    setIsOpenMobile(false);
  }



  const rightMenuToRender = NAVMENURIGHTITEMS || [];
  
  return (
    <NavbarParentContainer>
      <NavbarContainer>
        <HeaderContainer>
          {/* <MobileNavContainer>
            <div
              onClick={() => setIsOpenMobile(!isOpenMobile)}
              id="hamburger-icon-container"
            >
              <img
                src={isOpenMobile ? cross_X : hamburgerMenuIcon}
                alt="menu-icon"
              />
            </div>
            {isOpenMobile && (
              <MobileNavListContainer>
                <MobileNavList>
                  <p className="mobile_nav_title">Directory</p>
                  {NAVMENUITEMS.map((item: any, index: number) => (
                    <MobileNavListItem
                      key={index}
                      onClick={() => handleMobileMenuItemClick(item.url)}
                    >
                      <span>{item.name}</span>
                      <img src={right_arrow} alt="right_arrow" />
                    </MobileNavListItem>
                  ))}
                  <p className="mobile_nav_title mobile_nav_second_title">
                    Additional Links
                  </p>
                  {NAVMENURIGHTITEMS.map((item: any, index: number) => (
                    <MobileNavListItem
                      key={index}
                      onClick={() => handleMobileMenuItemClick(item.url)}
                    >
                      <span>{item.name}</span>
                      <img src={right_arrow} alt="right_arrow" />
                    </MobileNavListItem>
                  ))}
                  <MobileNavListItem
                    onClick={() => handleMobileMenuItemClick("/help")}
                  >
                    <span>Help</span>
                    <img src={right_arrow} alt="right_arrow" />
                  </MobileNavListItem>
                  <MobileNavListItem
                  >
                    <span>{"Login"}</span>
                    <img src={right_arrow} alt="right_arrow" />
                  </MobileNavListItem>
                  <MobileNavListItem onClick={() => history.push("#")}>
                    <span>Try For Free</span>
                    <img src={right_arrow} alt="right_arrow" />
                  </MobileNavListItem>
                </MobileNavList>
              </MobileNavListContainer>
            )}
          </MobileNavContainer> */}
          <div onClick={() => history.push("/")} id="logo-container">
            <img src={logo_full} alt="instant-reset-logo" />
          </div>
          {/* <MainNavContainer>
            {NAVMENUITEMS.map((item:any, index:any) => (
              <div>
                <MainNavListItem
                  key={index}
                  onClick={() => history.push(item.url)}
                >
                  {item.name}
                </MainNavListItem>
              </div>
            ))}
          </MainNavContainer>
          <RightNavContainer>
            <div id="rightNavContainer">
              {rightMenuToRender.map((item:any, index:any) => {
                if (item.name === "Dashboard") {
                  return (
                    <RightNavItems
                      key={index}
                      id="dropdown-button"
                    >
                      <DropDown
                        title={"Dashboard"}
                        options={[
                          { Profile: () => history.push("/profile") },
                          { Logout: () => null },
                        ]}
                      />
                    </RightNavItems>
                  );
                } else {
                  return (
                    <RightNavItems
                      key={index}
                      onClick={() => history.push(item.url)}
                    >
                      {item.name}
                    </RightNavItems>
                  );
                }
              })}
            </div>
            <BigButton>
              <span>Try for free</span>
            </BigButton>
          </RightNavContainer> */}
        </HeaderContainer>
      </NavbarContainer>
    </NavbarParentContainer>
  );
}

export default Navbar;
