/* eslint-disable no-undef */
import React from "react"
import jsonp from "jsonp"
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

// declare const gtag: any;

class Mailchimp extends React.Component {
  constructor(props) {  
    super(props);
    this.state = { trackingAlreadyCollected: false };
    this.setShowModal = props.setShowModal;
    this.history = props.history;
  }

  componentDidMount() {
    this.setState({ trackingAlreadyCollected: true });
  }

  handleSubmit(evt) {
    evt.preventDefault();
    const { fields, action } = this.props;
    const values = fields
      .map((field) => {
        return `${field.name}=${encodeURIComponent(this.state[field.name])}`;
      })
      .join("&");
    const path = `${action}&${values}`;
    const url = path.replace("/post?", "/post-json?");
    const regex = /^([\w_.\-+])+@([\w-]+\.)+([\w]{2,10})+$/;
    const email = this.state["EMAIL"];



    !regex.test(email)
      ? this.setState({ status: "empty" })
      : this.sendData(url);
  };


  async sendData(url) {
    this.setState({ status: "sending" });
    jsonp(url, { param: "c" }, (err, data) => {
      if (data.msg.includes("already subscribed")) {
        gtag("event", "Duplicate Submission", {
          event_category: "Email Submissions",
          event_label: "landing_page_modal_submission",
        });
        window.location.href = "/demo";
        this.setState({ status: 'duplicate' });
      } else if (err) {
        this.setState({ status: 'error' });
      } else if (data.result !== 'success') {
        this.setState({ status: 'error' });
      } else {
        this.setState({ status: 'success' }, () => {
          // const hashedEmail = sha256(this.state["EMAIL"]);
          // const hashedName = sha256(this.state["NAME"]);
          // const postUrl = `https://graph.facebook.com/v13.0/${process.env.REACT_APP_PIXEL_KEY}/events?access_token=${process.env.REACT_APP_FB_ACCESS_TOKEN}`;
          // const conversionData = {
          //   event_name: "Lead",
          //   event_time: Math.floor(Date.now() / 1000),
          //   user_data: {
          //     em: [
          //       hashedEmail,
          //       hashedName
          //     ],
          //   },
          //   event_source_url: window.location.href,
          //   action_source: "website",
          // };

          // axios
          //   .post(postUrl, {
          //     data: [conversionData],
          //   })
          gtag("event", "Email Submitted", {
            event_category: "Email Submissions",
            event_label: "landing_page_modal_submission",
          });
          localStorage.setItem(
            'MailChimpEmailVerified',
            true
          );
        window.location.href = "/demo";

        });
      };
    });
  }

  render() {
    const { fields, styles, className, buttonClassName } = this.props;
    const messages = {
      ...Mailchimp.defaultProps.messages,
      ...this.props.messages
    }
    const { status } = this.state;

    return (
      <form onSubmit={this.handleSubmit.bind(this)} className={className}>
        {fields.map((input) => (
          <TextField
            className="form_inputs"
            label={input.name.toLowerCase()}
            variant="outlined"
            {...input}
            key={input.name}
            onChange={({ target }) =>
              this.setState({ [input.name]: target.value })
            }
            defaultValue={this.state[input.name]}
          />
        ))}
        <Button
          disabled={status === "sending" || status === "success"}
          type="submit"
          className={buttonClassName}
          variant="contained"
        >
          {messages.button}
        </Button>
        <div className="msg-alert">
          {status === "sending" && (
            <p style={styles.sendingMsg}>{messages.sending}</p>
          )}
          {status === "success" && (
            <p style={styles.successMsg}>{messages.success}</p>
          )}
          {status === "duplicate" && (
            <p style={styles.duplicateMsg}>{messages.duplicate}</p>
          )}
          {status === "empty" && (
            <p style={styles.errorMsg}>{messages.empty}</p>
          )}
          {status === "error" && (
            <p style={styles.errorMsg}>{messages.error}</p>
          )}
        </div>
      </form>
    );
  }
}

Mailchimp.defaultProps = {
  messages: {
    sending: "Sending...",
    success: "Thank you for subscribing!",
    error: "An unexpected internal error has occurred.",
    empty: "You must write an e-mail.",
    duplicate: "Too many subscribe attempts for this email address",
    button: "Subscribe!"
  },
  buttonClassName: "",
  styles: {
    sendingMsg: {
      color: "#0652DD"
    },
    successMsg: {
      color: "#009432"
    },
    duplicateMsg: {
      color: "#EE5A24"
    },
    errorMsg: {
      color: "#ED4C67"
    }
  }
};

Mailchimp.propTypes = {
  action: PropTypes.string,
  messages: PropTypes.object,
  fields: PropTypes.array,
  styles: PropTypes.object,
  className: PropTypes.string,
  buttonClassName: PropTypes.string
};

var sha256 = function sha256(ascii) {
  function rightRotate(value, amount) {
    return (value >>> amount) | (value << (32 - amount));
  }

  var mathPow = Math.pow;
  var maxWord = mathPow(2, 32);
  var lengthProperty = "length";
  var i, j; // Used as a counter across the whole file
  var result = "";

  var words = [];
  var asciiBitLength = ascii[lengthProperty] * 8;

  //* caching results is optional - remove/add slash from front of this line to toggle
  // Initial hash value: first 32 bits of the fractional parts of the square roots of the first 8 primes
  // (we actually calculate the first 64, but extra values are just ignored)
  var hash = (sha256.h = sha256.h || []);
  // Round constants: first 32 bits of the fractional parts of the cube roots of the first 64 primes
  var k = (sha256.k = sha256.k || []);
  var primeCounter = k[lengthProperty];
  /*/
    var hash = [], k = [];
    var primeCounter = 0;
    //*/

  var isComposite = {};
  for (var candidate = 2; primeCounter < 64; candidate++) {
    if (!isComposite[candidate]) {
      for (i = 0; i < 313; i += candidate) {
        isComposite[i] = candidate;
      }
      hash[primeCounter] = (mathPow(candidate, 0.5) * maxWord) | 0;
      k[primeCounter++] = (mathPow(candidate, 1 / 3) * maxWord) | 0;
    }
  }

  ascii += "\x80"; // Append Ƈ' bit (plus zero padding)
  while ((ascii[lengthProperty] % 64) - 56) ascii += "\x00"; // More zero padding
  for (i = 0; i < ascii[lengthProperty]; i++) {
    j = ascii.charCodeAt(i);
    if (j >> 8) return; // ASCII check: only accept characters in range 0-255
    words[i >> 2] |= j << (((3 - i) % 4) * 8);
  }
  words[words[lengthProperty]] = (asciiBitLength / maxWord) | 0;
  words[words[lengthProperty]] = asciiBitLength;

  // process each chunk
  for (j = 0; j < words[lengthProperty]; ) {
    var w = words.slice(j, (j += 16)); // The message is expanded into 64 words as part of the iteration
    var oldHash = hash;
    // This is now the undefinedworking hash", often labelled as variables a...g
    // (we have to truncate as well, otherwise extra entries at the end accumulate
    hash = hash.slice(0, 8);

    for (i = 0; i < 64; i++) {
      var i2 = i + j;
      // Expand the message into 64 words
      // Used below if
      var w15 = w[i - 15],
        w2 = w[i - 2];

      // Iterate
      var a = hash[0],
        e = hash[4];
      var temp1 =
        hash[7] +
        (rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) + // S1
        ((e & hash[5]) ^ (~e & hash[6])) + // ch
        k[i] +
        // Expand the message schedule if needed
        (w[i] =
          i < 16
            ? w[i]
            : (w[i - 16] +
                (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15 >>> 3)) + // s0
                w[i - 7] +
                (rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2 >>> 10))) | // s1
              0);
      // This is only used once, so *could* be moved below, but it only saves 4 bytes and makes things unreadble
      var temp2 =
        (rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) + // S0
        ((a & hash[1]) ^ (a & hash[2]) ^ (hash[1] & hash[2])); // maj

      hash = [(temp1 + temp2) | 0].concat(hash); // We don't bother trimming off the extra ones, they're harmless as long as we're truncating when we do the slice()
      hash[4] = (hash[4] + temp1) | 0;
    }

    for (i = 0; i < 8; i++) {
      hash[i] = (hash[i] + oldHash[i]) | 0;
    }
  }

  for (i = 0; i < 8; i++) {
    for (j = 3; j + 1; j--) {
      var b = (hash[i] >> (j * 8)) & 255;
      result += (b < 16 ? 0 : "") + b.toString(16);
    }
  }
  return result;
};

export default withRouter(Mailchimp);
