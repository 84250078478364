import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import NotFound from "./pages/404_page";
import Layout from "./components/layout";
import { Reset } from "./styles/style-reset"; //style reset sheet
import { GlobalStyle } from "./styles/global"; //style global sheet
import LandingPageModal from "./components/signup_modal/landing_page_button_modal";
function App(props: any) {
  const displayScreen = () => {
    return (
      <div>
        <Reset />
        <GlobalStyle />
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              <Layout background="linear-gradient(245deg,#14279B,#1225a1)">
                <div>Hello World</div>
                <LandingPageModal />
              </Layout>
            </Route>
            <Route>
              <Layout background="#2f3242">
                <NotFound />
              </Layout>
            </Route>
          </Switch>
        </BrowserRouter>
      </div>
    );
  };

  return displayScreen();
}

export default App;


