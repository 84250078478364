import React from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import { LayoutContainer, PageContentContainer } from "./style";

function Layout({
  children,
  background,
}: {
  children: React.ReactNode;
  background?: string;
}) {
  return (
    <LayoutContainer>
      <Navbar />
      <PageContentContainer background={background || ""}>
        <div className="global-container">{children}</div>
      </PageContentContainer>
      <Footer />
    </LayoutContainer>
  );
}

export default Layout;