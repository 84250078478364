import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap');

  body {
    font-family: "Dosis", sans-serif;
  } 

  html {
    overflow-x: hidden;
    margin-right: calc(-1 * (100vw - 100%));
  }

  #session_form_container, .css-gadse4-MuiPaper-root-MuiGrid-root {
    box-shadow: 5px 0 5px -5px rgba(0,0,0,0.7),
-5px 0 5px -5px rgba(0,0,0,0.7);
  }
`;