import styled from "styled-components";
import {screenBreakpointValues} from "../../../utils/contants"
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";


export const NavbarParentContainer = styled.div`
  position: relative;
  font-family: "Dosis", sans-serif;

  #fade-menu {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const NavbarContainer = styled.div`
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 8;
  background-color: #ffffff;
  color: #413d45;
`;

export const HeaderContainer = styled.header`
  position: relative;
  display: flex;
  height: 4rem;
  margin: auto;
  max-width: 100%;
  padding: 0 1.25rem;
  align-items: center;
  cursor: pointer;

  #logo-container {
    width: 8rem;
    height: 4rem;
    display: flex;
    align-items: center;
    > img {
      height: 40px;
      width: 136px;
      max-width: 136px;
    }
  }

  @media screen and (min-width: ${screenBreakpointValues.sm}) {
    height: 5rem;
    padding: 0 2rem;
    #logo-container {
      width: 9rem;
      height: 5rem;

      > img {
        height: 48px;
      }
    }
  }

  @media screen and (min-width: ${screenBreakpointValues.lg}) {
    max-width: 90rem;
  }

  // smallest the components will shrink to
  @media screen and (max-width: ${screenBreakpointValues.sm}) {
    min-width: 375px;
  }
`;

export const MainNavContainer = styled.nav`
  display: flex;
  height: 100%;
  width: 100%;
  display: none;
  justify-self: start;
  padding-left: 5rem;
  padding-right: 1.5rem;
  cursor: default;

  & > div {
    height: 100%;
  }

  @media screen and (min-width: ${screenBreakpointValues.lg}) {
    display: flex;
  }
`;

export const MainNavListItem = styled.div`
  align-items: center;
  border-radius: unset;
  color: inherit;
  cursor: pointer;
  display: flex;
  font-weight: normal;
  height: 100%;
  overflow: visible;
  padding: 0 0.5rem;
  position: relative;
  text-decoration: none;
  font-weight: 500;
  font-size: 22px;

  ::after {
    background-color: transparent;
    border-radius: 2px 2px 0px 0px;
    bottom: 0;
    content: "";
    height: 0.25rem;
    left: 0.75rem;
    position: absolute;
    right: 0.75rem;
  }

  :hover::after {
    background-color: #ef2e3d;
  }

  :hover {
    color: #ef2e3d;
  }

  @media screen and (min-width: ${screenBreakpointValues.lg}) {
    padding: 0 0.75rem;
    padding-top: 10px;
  }
`;

export const ButtonDropDownContainer = styled.div`

`;

export const MenuEdited = styled(Menu)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MenuItemEdited = styled(MenuItem)`
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > span {
    display: none;
  }
`;

export const RightNavContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  cursor: default;
  justify-content: flex-end;

  #rightNavContainer {
    height: 100%;
    display: flex;
    justify-content: flex-end;
  } 

  #dropdown-button:hover::after {
    background-color: transparent;
  }
  #dropdown-button:hover {
    color: inherit;
  }

  @media screen and (min-width: ${screenBreakpointValues.lg}) {
    display: flex;
  }
`;

export const RightNavItems = styled(MainNavListItem)`
  display: none;
  padding-top: 10px;

  @media screen and (min-width: ${screenBreakpointValues.sm}) {
    display: flex;
  }
`;

export const BigButton = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;

  span {
    cursor: pointer;
    text-transform: uppercase;
    width: 100%;
    background: #0c6ff9;
    border-color: transparent;
    border-radius: 2rem;
    color: #ffffff;
    opacity: 1;
    padding: 15px 20px;
    font-weight: 600;
    font-size: 12px;
  }

  span:hover {
    background: #084fff;
  }

  @media screen and (min-width: ${screenBreakpointValues.md}) {
    span {
      padding: 20px 30px;
    }
  }

  @media screen and (min-width: ${screenBreakpointValues.sm}) {
    margin-left: 1.5rem;
    span {
      font-size: 16px;
    }
  }

  @media screen and (max-width: ${screenBreakpointValues.sm}) {
    min-width: 128px;
  }
`;

export const MobileNavContainer = styled.div`
  display: flex;
  font-size: 2rem;
  background: #ffffff;
  border: 0.125rem solid transparent;
  cursor: pointer;
  padding-right: 8px;
  padding-top: 10px;


  @media screen and (min-width: ${screenBreakpointValues.lg}) {
    display: none;
  }
`;

export const MobileNavListContainer = styled.div`
  background: #fff;
  position: fixed;
  border-top: 1px solid rgb(210, 206, 200);
  inset: 4rem 0px 0px;
  overflow: auto;
  position: fixed;
  z-index: 8;
  @media screen and (min-width: ${screenBreakpointValues.sm}) {
    inset: 5rem 0px 0px;
  }
`;

export const MobileNavList = styled.div`
  min-height: 100%;
  padding: 2.5rem 1.25rem 4rem;
  position: relative;
  margin: auto;

  .mobile_nav_title {
    font-size: 16px;
    margin: 0 0 24px
  }

  .mobile_nav_second_title {
    margin-top: 20px;
  }
  @media screen and (min-width: ${screenBreakpointValues.sm}) {
    max-width: 49rem;
  }
`;

export const MobileNavListItem = styled.button`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  padding-bottom: 12px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: transparent;
  opacity: 1;
  width: 100%;
  font-size: 2.25rem;
  font-weight: 700;
  letter-spacing: -0.03em;
  line-height: 2.625rem;
  border: 0px;
  color: rgb(65, 61, 69);
  height: auto;
  max-width: 100%;
  border-radius: 5rem;

  &:hover {
    background: #f8f8f8;
    border-color: transparent;
    opacity: 1;
  }
`;


