import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Redirect, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import {
  FooterContainer,
  BothFooterContainer,
  TopFooterContainer,
  TopDesktopFooterContainer,
  TopMobileFooterContainer,
  BottomFooterContainer,
  SocialIconContainer,
  FooterMenuSection,
  FooterRightSection,
  FooterMenuSectionItem,
} from "./style";

import {
  BigButton
} from "../navbar/style";

import {
  MDBIcon,
} from "mdb-react-ui-kit";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import iosComingSoon from "../../../static/iosComingSoon.svg";
import andriodComingSoon from "../../../static/andriodComingSoon.svg";


const SOCIALS = [
  {
    name: "Facebook",
    url: "https://www.facebook.com/",
    icon: "facebook-f",
  },
  {
    name: "Twitter",
    url: "https://www.twitter.com/",
    icon: "twitter",
  },
  {
    name: "Instagram",
    url: "https://www.instagram.com/",
    icon: "instagram",
  },
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/",
    icon: "linkedin-in",
  },
  {
    name: "Podcast",
    url: "https://www.podcast.com/",
    icon: "podcast",
  },
  {
    name: "YouTube",
    url: "https://www.youtube.com/",
    icon: "youtube",
  },
];

const FOOTERMENUSECTIONONE = [
  {
    name: "Home",
    url: "/#",
  },
  {
    name: "Podcast",
    url: "/podcast#",
  },
]

const FOOTERMENUSECTIONTWO = [
  {
    name: "Who We Are",
    url: "/about-us#",
  },
  {
    name: "Get in Touch",
    url: "/contact-us#",
  },
  // {
  //   name: "Careers",
  //   url: "#",
  // },
];


const FOOTERMENUSECTIONTHREE = [
  {
    name: "Help",
    url: "/contact-us#",
  },
  {
    name: "Contact",
    url: "/contact-us#",
  },
  {
    name: "Privacy Policy",
    url: "/privacy-policy#",
  },
  {
    name: "Terms of Use",
    url: "/term-of-use#",
  },
  {
    name: "Cookie Policy",
    url: "/privacy-policy/#cookie-policy",
  },
];

function Footer(props: any) {
  const [mobileSectionOneExpanded, setMobileSectionOneExpanded] =
    useState(false)
  const [mobileSectionTwoExpanded, setMobileSectionTwoExpanded] = useState(false)
  const [mobileSectionThreeExpanded, setMobileSectionThreeExpanded] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  let history = useHistory();


  return (
    <FooterContainer>
      <BothFooterContainer>
        {/* <TopFooterContainer>
          <TopDesktopFooterContainer>
            <FooterMenuSection>
              <FooterMenuSectionItem>
                <p className="footer_menu_header">Get Focused</p>
                <ul className="footer_menu_list">
                  {FOOTERMENUSECTIONONE.map((item: any, index: number) => {
                    return (
                      <li key={index} className="footer_menu_item">
                        <Link to={item.url}>{item.name}</Link>
                      </li>
                    );
                  })}
                </ul>
              </FooterMenuSectionItem>
              <FooterMenuSectionItem>
                <p className="footer_menu_header">About Us</p>
                <ul className="footer_menu_list">
                  {FOOTERMENUSECTIONTWO.map((item: any, index: number) => {
                    return (
                      <li key={index} className="footer_menu_item">
                        <Link to={item.url}>{item.name}</Link>
                      </li>
                    );
                  })}
                </ul>
              </FooterMenuSectionItem>
              <FooterMenuSectionItem>
                <p className="footer_menu_header">Support</p>
                <ul className="footer_menu_list">
                  {FOOTERMENUSECTIONTHREE.map((item: any, index: number) => {
                    return (
                      <li key={index} className="footer_menu_item">
                        <Link to={item.url}>{item.name}</Link>
                      </li>
                    );
                  })}
                </ul>
              </FooterMenuSectionItem>
            </FooterMenuSection>
            <FooterRightSection>
              <FooterMenuSectionItem className="footerRightSection_items">
                <p className="footer_menu_header">Account</p>
                <BigButton
                  id="footer_big_button"
                  onClick={() =>
                    isAuthenticated
                      ? history.push("/dashboard")
                      : history.push("/login")
                  }
                >
                  <span>{isAuthenticated ? "Dashboard" : "Login"}</span>
                </BigButton>
              </FooterMenuSectionItem>
              <FooterMenuSectionItem className="footerRightSection_last_item">
                <p className="footer_menu_header">Get The App</p>
                <img src={iosComingSoon} alt="Coming Soon" />
                <img src={andriodComingSoon} alt="Coming Soon" />
              </FooterMenuSectionItem>
            </FooterRightSection>
          </TopDesktopFooterContainer>
          <TopMobileFooterContainer>
            <div className="mobile-top-footer-big-button-container">
              <p id="mobile_footer_big_button_header">Account</p>
              <BigButton
                id="mobile_footer_big_button"
                onClick={() =>
                  isAuthenticated
                    ? history.push("/dashboard")
                    : history.push("/login")
                }
              >
                <span>{isAuthenticated ? "Dashboard" : "Login"}</span>
              </BigButton>
            </div>
            <div className="mobile-top-footer-menu-container">
              <Accordion
                id="mobile-top-footer_menu_list"
                onClick={() =>
                  setMobileSectionOneExpanded(!mobileSectionOneExpanded)
                }
                expanded={mobileSectionOneExpanded}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p className="footer_menu_header">Get Focused</p>
                </AccordionSummary>
                <AccordionDetails>
                  <ul className="footer_menu_list">
                    {FOOTERMENUSECTIONONE.map((item: any, index: number) => {
                      return (
                        <li key={index} className="footer_menu_item">
                          <Link to={item.url}>{item.name}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </AccordionDetails>
              </Accordion>
              <Accordion
                id="mobile-top-footer_menu_list"
                onClick={() =>
                  setMobileSectionTwoExpanded(!mobileSectionTwoExpanded)
                }
                expanded={mobileSectionTwoExpanded}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p className="footer_menu_header">About Us</p>
                </AccordionSummary>
                <AccordionDetails>
                  <ul className="footer_menu_list">
                    {FOOTERMENUSECTIONTWO.map((item: any, index: number) => {
                      return (
                        <li key={index} className="footer_menu_item">
                          <Link to={item.url}>{item.name}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </AccordionDetails>
              </Accordion>
              <Accordion
                id="mobile-top-footer_menu_list"
                onClick={() =>
                  setMobileSectionThreeExpanded(!mobileSectionThreeExpanded)
                }
                expanded={mobileSectionThreeExpanded}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p className="footer_menu_header">Support</p>
                </AccordionSummary>
                <AccordionDetails>
                  <ul className="footer_menu_list">
                    {FOOTERMENUSECTIONTHREE.map((item: any, index: number) => {
                      return (
                        <li key={index} className="footer_menu_item">
                          <Link to={item.url}>{item.name}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </AccordionDetails>
              </Accordion>
            </div>
          </TopMobileFooterContainer>
        </TopFooterContainer> */}
        <BottomFooterContainer>
          <SocialIconContainer>
            {SOCIALS.map((social: any) => {
              return (
                <a
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-social-anchor"
                >
                  <li key={social.name}>
                    {social.name !== "Podcast" ? (
                      <MDBIcon
                        fab
                        icon={social.icon}
                        className="footer-social-icons"
                      />
                    ) : (
                      <MDBIcon
                        fas
                        icon={social.icon}
                        className="footer-social-icons"
                      />
                    )}
                  </li>
                </a>
              );
            })}
          </SocialIconContainer>
          <Copyright />
        </BottomFooterContainer>
      </BothFooterContainer>
    </FooterContainer>
  );
}


function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" to="/#">
        Qurangym.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
export default Footer;
